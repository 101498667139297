import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Box, Tooltip, Button } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import Loading from '../helpers/Loading'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
import { crStyles } from './crStyles'
import Widget from '../Widget'
import {
  a11yProps,
  TabPanel,
} from '../../DashboardComponents/TabPanel/TabPanel'
import {
  RutgersTabs,
  RutgersTab,
} from '../../DashboardComponents/Tabs/RutgersTabs'

import { logDashboardEvent } from '../../events'
import {
  selectCRData,
  selectCRStatus,
  fetchCommuterResources,
  fetchCommuterResourcesImage,
  fetchCommuterResourcesEvents,
} from './CommuterResourceSlice'
import WidgetFooterButton from './WidgetFooterButton'
import CRamenities from './components/CRamenities'
import CRaboutUs from './components/CRaboutUs'
import CRcontact from './components/CRcontact'
import CReventsList from './components/CReventsList'
import CRcheckList from './components/CRcheckList'
import InlineSocialMediaIcons from '../../DashboardComponents/InlineSocialMediaIcons/InlineSocialMediaIcons'

const CommuterResources = ({
  title,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
  widgetId,
}) => {
  // use above when API is ready
  const dispatch = useDispatch()
  const widgetData = useSelector(selectCRData)
  const widgetCallState = useSelector(selectCRStatus)
  const darkMode = useSelector((s) => s?.settings?.data?.darkMode)

  React.useEffect(async () => {
    dispatch(fetchCommuterResources())
    dispatch(fetchCommuterResourcesEvents())
  }, [])

  React.useEffect(async () => {
    // fetches firebase url for all images
    // This prevents flickering of images on tab change after first load
    if (widgetCallState === 'fulfilled') {
      await widgetData.amenities?.lounges?.forEach((lounge, i) => {
        dispatch(
          fetchCommuterResourcesImage({
            image: lounge.image,
            type: `amenities`,
            index: i,
          }),
        )
      })
      // events
      await dispatch(
        fetchCommuterResourcesImage({
          image: widgetData.events.image,
          type: `events`,
        }),
      )
      // about
      await dispatch(
        fetchCommuterResourcesImage({
          image: widgetData.about.image,
          type: `about`,
        }),
      )
      // social
      await widgetData.footer?.inlineSocialMediaIcons?.forEach((social, i) => {
        let iconURL = social.icon
        const indexToAddDark = social.icon.lastIndexOf('.')
        const darkModeImageLink = `${social.icon.slice(
          0,
          indexToAddDark,
        )}-dark${social.icon.slice(indexToAddDark)}`
        if (i === 2) {
          if (darkMode) {
            iconURL = darkModeImageLink
          }
        }
        dispatch(
          fetchCommuterResourcesImage({
            image: iconURL,
            type: `social`,
            index: i,
          }),
        )
      })
    }
  }, [widgetCallState])

  return (
    <Widget>
      <Widget.Header title={title} />
      <Widget.Content
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderCRstate(widgetCallState, widgetData, widgetId, title)}
      </Widget.Content>
    </Widget>
  )
}

function renderCRstate(widgetCallState, widgetData, widgetId, title) {
  // when backend is ready switch should use widgetCallState
  switch (widgetCallState) {
    case 'fulfilled':
      return (
        <Loaded widgetData={widgetData} widgetId={widgetId} title={title} />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded({ widgetData, widgetId, title }) {
  // when API is ready add back widgetData prop
  const theme = useTheme()
  const classes = crStyles()
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = React.useState(0)
  const [showMainView, setShowMainView] = React.useState(1)
  const resources = ['Events', 'Amenities', 'Checklist', 'Contact']

  const handleTabChange = (e, value) => {
    setCurrentTab(value)
  }

  function switchView(view) {
    setShowMainView(Boolean(view))
  }
  const handleClick = (url, eventName) => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardLinkEvent',
        widgetId,
        eventName ?? title,
        url,
      )
      window.open(url, '_blank')
    }
  }

  const inlineSocialMediaIconsItems1 = [
    widgetData.footer.inlineSocialMediaIcons[0],
    widgetData.footer.inlineSocialMediaIcons[1],
  ]

  return (
    <>
      {showMainView ? (
        <>
          <Box className={classes.modalTabs}>
            <RutgersTabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="Commuter Resources Tabs"
              className={classes.tabBackground}
            >
              {resources.map((tab, index) => (
                <RutgersTab key={tab} label={tab} {...a11yProps(index)} />
              ))}
            </RutgersTabs>
          </Box>
          <Box>
            <TabPanel
              value={currentTab}
              index={0}
              className={classes.tabPanel1}
              aria-labelledby={resources[0]}
            >
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
              >
                <CReventsList
                  events={widgetData.eventsList}
                  handleClick={handleClick}
                  backupData={widgetData.events}
                />
              </Grid>
            </TabPanel>
            <TabPanel
              value={currentTab}
              index={1}
              className={classes.tabPanel0}
              aria-labelledby={resources[1]}
            >
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
              >
                <CRamenities data={widgetData.amenities} />
              </Grid>
            </TabPanel>
            <TabPanel
              value={currentTab}
              index={2}
              className={classes.tabPanel1}
              aria-labelledby={resources[2]}
            >
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
              >
                <CRcheckList data={widgetData.checklist} />
              </Grid>
            </TabPanel>
            <TabPanel
              value={currentTab}
              index={3}
              className={classes.tabPanel1}
              aria-labelledby={resources[3]}
            >
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
              >
                <CRcontact
                  handleClick={handleClick}
                  data={widgetData.contact}
                />
              </Grid>
            </TabPanel>
          </Box>
        </>
      ) : (
        <CRaboutUs handleClick={switchView} data={widgetData.about} />
      )}

      <Box className={classes.footer}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          className={classes.iconfooter}
        >
          <Grid item>
            <InlineSocialMediaIcons
              buttons={inlineSocialMediaIconsItems1}
              widgetId={widgetId}
              dispatch={dispatch}
              aria-hidden="true"
            />
          </Grid>
          <Grid item className={classes.footerIcon}>
            <Tooltip
              title={widgetData?.footer?.inlineSocialMediaIcons[2]?.iconLabel}
              key={
                widgetData?.footer?.inlineSocialMediaIcons[2]?.link +
                widgetData?.footer?.inlineSocialMediaIcons[2]?.iconLabel
              }
              placement="top"
              arrow
            >
              <Button
                onClick={() =>
                  handleClick(
                    widgetData?.footer?.inlineSocialMediaIcons[2]?.link,
                    widgetData?.footer?.inlineSocialMediaIcons[2]?.iconLabel,
                  )
                }
              >
                <img
                  src={widgetData?.footer?.inlineSocialMediaIcons[2]?.iconFinal}
                  alt={widgetData?.footer?.inlineSocialMediaIcons[2]?.iconLabel}
                  height={20}
                  width={100}
                  style={{ marginTop: '4px' }}
                  aria-hidden="true"
                />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item className={classes.footerIcon}>
            <Tooltip
              title={widgetData?.footer?.inlineSocialMediaIcons[3]?.iconLabel}
              key={
                widgetData?.footer?.inlineSocialMediaIcons[3]?.link +
                widgetData?.footer?.inlineSocialMediaIcons[3]?.iconLabel
              }
              placement="top"
              arrow
            >
              <Button
                onClick={() => setShowMainView(!showMainView)}
                role="presentation"
              >
                <img
                  src={widgetData?.footer?.inlineSocialMediaIcons[3].iconFinal}
                  alt={widgetData?.footer?.inlineSocialMediaIcons[3]?.iconLabel}
                  height={20}
                  width={100}
                  aria-hidden="true"
                />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>

        <WidgetFooterButton
          handleClick={() =>
            handleClick(
              widgetData?.footer?.buttonLink,
              widgetData.footer.buttonText,
            )
          }
          title={widgetData.footer.buttonText}
          bgColor={theme.color.rcsaBlue}
        />
      </Box>
    </>
  )
}

export default CommuterResources

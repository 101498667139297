/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchMasonGrossTheater = createAsyncThunk(
  'fetchMasonGrossTheater',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    console.log(12345, process.env.REACT_APP_MASONGROSSTHEATER_ENDPOINT)
    const res = await axios.get(
      process.env.REACT_APP_MASONGROSSTHEATER_ENDPOINT,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

const initialState = {
  status: 'idle',
  data: {
    title: 'Mason Gross Theater',
  },
}

const masonGrossSlice = createSlice({
  name: 'masonGrossTheater',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMasonGrossTheater.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMasonGrossTheater.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMasonGrossTheater.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = 'fulfilled'
    },
  },
})

export const selectMasonGrossTheaterData = (state) =>
  state.masonGrossTheater.data
export const selectMasonGrossTheaterStatus = (state) =>
  state.masonGrossTheater.status

export default masonGrossSlice.reducer

import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  container: {
    margin: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  content: {
    padding: theme.spacing(0.5, 0.5, 0, 0.5),
  },
  body: {
    minHeight: '100%',
  },
  iconsGridItem: {
    width: '100%',
  },
  imageGrid: {
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  image: {
    width: '100%',
  },
  footer: {
    width: '100%',
    position: 'sticky',
    bottom: 0,
    left: 0,
    background: theme.palette.backgroundColor.secondary,
    borderTop: `1px solid ${theme.palette.backgroundColor.main}`,
  },
}))

export default styles

import * as React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import {
  selectApps,
  selectMyFavoriteAppsList,
  selectReorderFavoriteApps,
} from '../../appSlice'
import ReorderAppList from '../../AppComponents/ReorderFavoriteAppsList/ReorderFavoriteAppsList'
import SetNumOfAppAltPerRow from '../../AppComponents/ShowAppList/NumOfAppAltPerRowHook'
import ShowAppList from '../../AppComponents/ShowAppList/ShowAppList'
import SetCarouselDimensions from '../../AppComponents/AppCarouselCombined/AppCarouselDimensions'
import useFavoritesPageStyles from './FavoritesAppPageStyles'
import { MyFavoritesEmptyComponent } from '../../../dashboard/widgets/MyFavorites/MyFavoritesEmpty'

function FavoritesAppPage({ isMobile }) {
  const classes = useFavoritesPageStyles()
  const appsList = useSelector(selectApps)
  const numAppPerRow = SetNumOfAppAltPerRow()
  const dimensions = SetCarouselDimensions()
  const myFavoriteAppsList = useSelector(selectMyFavoriteAppsList)
  const selectReorderFavoriteAppsList = useSelector(selectReorderFavoriteApps)
  const [favoritesAppList, setFavoriteAppsList] = React.useState([])

  // Create the favorites app list initially
  React.useEffect(() => {
    createFavoriteAppsList()
  }, [])

  // Recreate the list whenever the favorites app list is changed (Reorder)
  React.useEffect(() => {
    createFavoriteAppsList()
  }, [myFavoriteAppsList])

  const createFavoriteAppsList = () => {
    const appIndexList = myFavoriteAppsList
    const extractedFavoritesApps = {}

    appIndexList.forEach((id, index) => {
      extractedFavoritesApps[id.toString()] = { order: index, app: null }
    })
    appsList.forEach((app) => {
      const appID = app.id.toString()
      if (appID in extractedFavoritesApps) {
        extractedFavoritesApps[appID].app = app
      }
    })

    const extractedFavoritesAppsList = Object.values(extractedFavoritesApps)

    const removeNullFromExtractedFavoritesAppsList =
      extractedFavoritesAppsList.filter((el) => el.app != null)

    const sortedRemoveNullFromExtractedFavoritesAppsList =
      removeNullFromExtractedFavoritesAppsList.sort((a, b) =>
        a.order > b.order ? 1 : -1,
      )
    const finalFavoritedAppsList =
      sortedRemoveNullFromExtractedFavoritesAppsList
        .map((app) => app.app)
        .filter((appList) => appList.active)

    setFavoriteAppsList(finalFavoritedAppsList)
  }

  function renderWebFavoritesPage() {
    return selectReorderFavoriteAppsList ? (
      <ReorderAppList
        isMobile={isMobile}
        favoritesAppList={favoritesAppList}
        dimensions={dimensions}
      />
    ) : (
      <Grid
        container
        justifyContent="space-evenly"
        className={classes.favoritesRoot}
      >
        {!isMobile && (
          <ShowAppList
            inputAppList={favoritesAppList.slice(0, numAppPerRow)}
            appAltList
          />
        )}

        <ShowAppList
          inputAppList={favoritesAppList.slice(numAppPerRow)}
          dimensions={dimensions}
          isMobile={isMobile}
          showListEmptyComponent={isMobile}
        />
      </Grid>
    )
  }

  return (
    <div className={classes.root}>
      <>
        {favoritesAppList.length ? (
          renderWebFavoritesPage()
        ) : (
          <MyFavoritesEmptyComponent />
        )}
      </>
    </div>
  )
}

export default FavoritesAppPage

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'

// icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { AccordionDetails } from '@material-ui/core'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import Widget from '../Widget'
import {
  fetchMyCourseActivity,
  resetAckStatus,
  selectMyCourseActivityData,
  selectMyCourseActivityStatus,
  updateAckFlag,
  updateTodoTask,
  updateUserGradeTask,
} from './MyCourseActivitySlice'
import styles from './MyCourseActivityStyles'
import Loading from '../helpers/Loading'
import { a11yProps } from '../../DashboardComponents/TabPanel/TabPanel'
import {
  RutgersTab,
  RutgersTabs,
} from '../../DashboardComponents/Tabs/RutgersTabs'
import { logDashboardEvent } from '../../events'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

// const MS_PER_DAY = 1000 * 60 * 60 * 24
// eslint-disable-next-line no-unused-vars
function MyCourseActivity({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const widgetData = useSelector(selectMyCourseActivityData)
  const widgetStatus = useSelector(selectMyCourseActivityStatus)
  const classes = styles()

  const sortOptions = ['By Course', 'By Date', 'By History']
  const sortCallOptions = ['ByCourse', 'ByDate', 'ByHistory']
  const [sort, setSort] = React.useState(0)

  const filterOptions = ['Activity', 'Grades']
  const filterCallOptions = ['todoList', 'userGradeList']
  const [filter, setFilter] = React.useState(0)

  React.useEffect(() => {
    dispatch(fetchMyCourseActivity(sortCallOptions[sort]))
  }, [sort])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        className={classes.container}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        <SortingTabs
          sort={sort}
          setSort={setSort}
          sortOptions={sortOptions}
          classes={classes}
        />
        <FilterTabs
          filter={filter}
          setFilter={setFilter}
          filterOptions={filterOptions}
          classes={classes}
        />
        <Box className={classes.accordionHolder}>
          {renderCourseActivity(
            widgetStatus,
            widgetData,
            filterCallOptions[filter],
            classes,
            widgetId,
          )}
        </Box>
      </Widget.Content>
    </Widget>
  )
}

function renderCourseActivity(
  widgetStatus,
  widgetData,
  filter,
  classes,
  widgetId,
) {
  switch (widgetStatus) {
    case 'fulfilled':
      return (
        <Loaded
          widgetData={widgetData}
          classes={classes}
          filter={filter}
          widgetId={widgetId}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorMessage />
    default:
      return <Box />
  }
}

function Loaded({ widgetData, classes, filter, widgetId }) {
  return (
    <Grid container direction="column" spacing={1} className={classes.root}>
      {widgetData.tabData?.map((course) =>
        course.courseInfo ? (
          <Grid item key={course.courseInfo.courseId}>
            <Accordion
              className={classes.accordion}
              variant="outlined"
              elevation={1}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {course.courseInfo.courseTitle}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                {course[filter].length === 0 ? (
                  <Typography variant="body1" className={classes.nothing}>
                    You&apos;re all caught up!
                  </Typography>
                ) : (
                  <Grid container direction="column" spacing={1}>
                    {course[filter].map((item, index) => (
                      <Grid item key={item.itemId}>
                        <ActivityCard
                          item={item}
                          classes={classes}
                          widgetId={widgetId}
                          index={index}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ) : (
          <Grid item>
            <Grid container direction="column" spacing={1}>
              {course[filter]?.map((item, index) => (
                <Grid item key={item.itemId}>
                  <ActivityCard
                    item={item}
                    classes={classes}
                    widgetId={widgetId}
                    index={index}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        ),
      )}
      {widgetData.tabData?.length === 0 || !widgetData.tabData ? (
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            marginLeft="auto"
            marginRight="auto"
            flexDirection="column"
          >
            <IconPicker iconName="check-circle" color="#00b339" size="5x" />
            <Typography variant="subtitle1">No Course Activity Data</Typography>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  )
}

function SortingTabs({ sort, setSort, sortOptions, classes }) {
  const handleChange = (event, newValue) => {
    setSort(newValue)
  }

  return (
    <Box className={classes.rootTabBox}>
      <RutgersTabs
        value={sort}
        onChange={handleChange}
        aria-label="profile tabs"
        variant="fullWidth"
      >
        {sortOptions.map((tab, index) => (
          <RutgersTab
            key={String(tab)}
            tabIndex={0}
            label={tab}
            {...a11yProps(index)}
          />
        ))}
      </RutgersTabs>
    </Box>
  )
}
function FilterTabs({ filter, setFilter, filterOptions, classes }) {
  const handleChange = (event, newValue) => {
    setFilter(newValue)
  }

  return (
    <Box className={classes.rootTabBox}>
      <RutgersTabs
        value={filter}
        onChange={handleChange}
        aria-label="filter tabs"
        variant="fullWidth"
      >
        {filterOptions.map((tab, index) => (
          <RutgersTab
            key={String(tab)}
            tabIndex={0}
            label={tab}
            {...a11yProps(index)}
          />
        ))}
      </RutgersTabs>
    </Box>
  )
}

function ActivityCard({ item, classes, widgetId, index }) {
  const dispatch = useDispatch()
  const handleChange = (event) => {
    // TODO: the item with the new value + hooks can't be created here!
    // sync the ackFlag with the status of the submission?
    const updateObj = {
      item,
      value:
        event.target.checked && event.target.checked !== 'false' ? 'Y' : 'N',
    }
    // ITEM_ID = TODO
    if (item.grdBookId) {
      dispatch(updateUserGradeTask(updateObj))
        .then(() => dispatch(updateAckFlag({ ...updateObj, index })))
        .then(() => dispatch(resetAckStatus()))
    } else if (item.itemId) {
      dispatch(updateTodoTask(updateObj))
        .then(() => dispatch(updateAckFlag({ ...updateObj, index })))
        .then(() => dispatch(resetAckStatus()))
    }
  }
  const handleButtonClick = (link, buttonTitle) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, buttonTitle, link)
    }
    window.open(link)
  }
  return (
    <Card variant="outlined" className={classes.activityCard}>
      <Grid container direction="column">
        <Grid
          container
          item
          direction="row"
          alignItems="center"
          className={classes.generalRow}
        >
          <Checkbox
            checked={item.ackFlag && item.ackFlag !== 'false'}
            onChange={handleChange}
          />
          <Box
            component="a"
            href={item.itemUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.iconBox}
          >
            {getIcon(item.itemType, classes)}
          </Box>
          <Grid item xs>
            {/* TODO: format the title to be consistent across all platforms? */}
            <Box
              component="a"
              href={item.itemUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="body1" className={classes.todoTitle}>
                {item.title}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        {item.grdBookId === undefined ? (
          <ItemRow
            item={item}
            classes={classes}
            handleButtonClick={handleButtonClick}
          />
        ) : (
          <GradeRow
            item={item}
            classes={classes}
            handleButtonClick={handleButtonClick}
          />
        )}
      </Grid>
    </Card>
  )
}

function ItemRow({ item, classes, handleButtonClick }) {
  const timeClass = getClass(item, classes)
  return (
    <Grid
      container
      item
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className={classes.adaptiveRow}
    >
      <Grid item>
        {item.pts !== null ? (
          <Typography variant="body1">{item.pts} pts</Typography>
        ) : (
          <Box></Box>
        )}
      </Grid>
      <Grid item>
        <Button
          onClick={() => handleButtonClick(item.itemUrl, item.dueDt)}
          className={timeClass}
        >
          <Typography variant="button">{item.dueDt}</Typography>
        </Button>
      </Grid>
    </Grid>
  )
}

function GradeRow({ item, classes, handleButtonClick }) {
  const buttonTitle = `${item.grade} of ${item.totalPoints} pts`
  return (
    <Grid
      container
      item
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      className={classes.adaptiveRow}
    >
      <Grid item>
        <Button
          onClick={() => handleButtonClick(item.gradeUrl, buttonTitle)}
          className={classes.gradeButton}
        >
          <Typography variant="button">{buttonTitle}</Typography>
        </Button>
      </Grid>
    </Grid>
  )
}

function getIcon(type) {
  const intType = parseInt(type, 10)
  switch (intType) {
    case 1:
      // this is an announcement
      return <IconPicker iconName="bullhorn" size="2x" color="#e0a96f" />
    case 2:
      // this is an assignment
      return <IconPicker iconName="edit" size="2x" color="#cea7dd" />
    case 3:
      // this is course material
      return <IconPicker iconName="book" size="2x" color="#9fa6e2" />
    case 4:
      // this is a custon to do
      return <IconPicker iconName="edit" size="2x" color="#cea7dd" />
    case 5:
      // this is a discussion
      return <IconPicker iconName="comments" size="2x" color="#73b276" />
    case 6:
      // this is a grade
      return <IconPicker iconName="award" size="2x" color="#96bde2" />
    case 7:
      // this is a quiz
      return <IconPicker iconName="list-ol" size="2x" color="#d9618b" />
    case 8:
      // this is first day materials
      return <IconPicker iconName="book" size="2x" color="#cea7dd" />
    default:
      return <IconPicker iconName="award" size="2x" color="#96bde2" />
  }
}

function dateDiffInDays(a, b) {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return utc1 > utc2
}

function getClass(item, classes) {
  const lateAssignment = dateDiffInDays(
    new Date(),
    new Date(parseInt(item.itemDueDt, 10)),
  )

  if (lateAssignment === true) {
    return classes.red
  }
  return classes.green
}

export default MyCourseActivity

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  useTheme,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import AddWidgets from '../AddWidgets/AddWidgets'
import ReorderWidgets from '../ReorderWidgets/ReorderWidgets'
import {
  addWidgetOpen,
  addWidgetClose,
  selectAddWidgetIsOpen,
} from '../../../../navigation/navigationSlice'
import {
  setWidgetActive,
  setWidgetActiveLocal,
  selectWidgets,
  updateWidgets,
} from '../../dashboardSlice'
import CheckIsMobile from '../../../../../utils/isMobileHook'
import Modal from '../../../../CommonComponents/Modal/Modal'
import { RutgersTab, RutgersTabs } from '../Tabs/RutgersTabs'
import { TabPanel, a11yProps } from '../TabPanel/TabPanel'
import { ManageWidgetsStyles } from './ManageWidgetsStyles'

function TabsPanel({ classes, handleTabChange, tabValue }) {
  const tabs = ['Add a Widget', 'Reorder Widgets']

  return (
    <RutgersTabs
      value={tabValue}
      onChange={handleTabChange}
      variant="fullWidth"
    >
      {tabs.map((tab, index) => (
        <RutgersTab
          key={tab}
          label={tab}
          aria-label={tab}
          aria-labelledby={tab}
          className={classes.tab}
          {...a11yProps(index)}
        />
      ))}
    </RutgersTabs>
  )
}

function FooterButton({
  onSetClick,
  edited,
  selectedIndicies,
  tabValue,
  classes,
}) {
  const tab0HelperText =
    selectedIndicies?.length !== 0 ? (
      <>
        Add {selectedIndicies?.length} Widget
        {selectedIndicies?.length > 1 && 's'}
      </>
    ) : (
      <>Select a Widget</>
    )

  const tab1HelperText = <>Drag to reorder</>
  return (
    <>
      <Typography variant="body1" className={classes.helperText}>
        {tabValue === 0 ? tab0HelperText : tab1HelperText}
      </Typography>

      <Button
        onClick={onSetClick}
        variant="outlined"
        disabled={!edited}
        color="primary"
      >
        Set
      </Button>
    </>
  )
}

function ManageWidgets() {
  // Parent component that manages state for all it's children
  const dispatch = useDispatch()
  const classes = ManageWidgetsStyles()
  const openDialog = useSelector(selectAddWidgetIsOpen)
  const isMobile = CheckIsMobile()
  const history = useHistory()
  const [tabValue, setTabValue] = React.useState(0)
  const [edited, setEdited] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const [selectedWidgets, setSelectedWidgets] = React.useState([])
  const [expandedIndicies, setExpandedIndicies] = React.useState([])
  const [selectedIndicies, setSelectedIndicies] = React.useState([])
  const [dashboardWidgets, setDashboardWidgets] = React.useState([])
  const widgets = useSelector(selectWidgets)

  React.useEffect(() => {
    setDashboardWidgets(widgets)
  }, [widgets])

  const setOpen = () => {
    if (isMobile) {
      history.push({
        pathname: './ManageWidgetsMobile',
      })
    } else if (openDialog) {
      dispatch(addWidgetClose())
    } else {
      dispatch(addWidgetOpen())
    }
  }
  const handleClose = () => {
    setEdited(false)
    setTabValue(0)
    setOpen()
  }

  const addWidget = () => {
    setSearch('')
    let modifiedUserOrder = []
    let selectedWidgetIds = []
    dashboardWidgets.forEach((user, index) => {
      modifiedUserOrder[index] = { ...user }
    })
    for (let i = 0; i < selectedIndicies.length; i++) {
      dispatch(setWidgetActiveLocal(selectedWidgets[i]))
      modifiedUserOrder = [selectedWidgets[i], ...modifiedUserOrder]
      selectedWidgetIds = [selectedWidgets[i].id, ...selectedWidgetIds]
    }
    dispatch(setWidgetActive(selectedWidgetIds))
    setEdited(false)
    return modifiedUserOrder
  }

  const updateOrder = (newOrder) => {
    dispatch(updateWidgets(newOrder))
    setEdited(false)
  }

  const onSetClick = () => {
    const newOrder = addWidget()
    updateOrder(newOrder)
    dispatch(addWidgetClose())

    setTabValue(0)
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }
  return (
    <>
      <Tooltip
        title={<Typography variant="subtitle2">Manage Widgets</Typography>}
        placement="top"
        arrow
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Manage Widgets Modal"
          onClick={() => setOpen()}
        >
          <AddIcon className={classes.appBarIcon} />
        </IconButton>
      </Tooltip>
      <Modal
        open={openDialog}
        dialogTitle="Customize myDashboard"
        onCloseDialog={handleClose}
        dialogSubtitle={
          <TabsPanel
            classes={classes}
            handleTabChange={handleTabChange}
            tabValue={tabValue}
          />
        }
        dialogContent={
          <ManageWidgetsContent
            dispatch={dispatch}
            tabValue={tabValue}
            search={search}
            setSearch={setSearch}
            setEdited={setEdited}
            selectedWidgets={selectedWidgets}
            setSelectedWidgets={setSelectedWidgets}
            expandedIndicies={expandedIndicies}
            setExpandedIndicies={setExpandedIndicies}
            selectedIndicies={selectedIndicies}
            setSelectedIndicies={setSelectedIndicies}
            dashboardWidgets={dashboardWidgets}
            setDashboardWidgets={setDashboardWidgets}
            widgets={widgets}
          />
        }
        dialogActions={
          <FooterButton
            onSetClick={onSetClick}
            edited={edited}
            selectedIndicies={selectedIndicies}
            tabValue={tabValue}
            classes={classes}
          />
        }
      />
    </>
  )
}

function ManageWidgetsContent({
  dispatch,
  tabValue,
  search,
  setSearch,
  setEdited,
  selectedWidgets,
  setSelectedWidgets,
  expandedIndicies,
  setExpandedIndicies,
  selectedIndicies,
  setSelectedIndicies,
  dashboardWidgets,
  setDashboardWidgets,
  widgets,
}) {
  const theme = useTheme()
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <TabPanel
          value={tabValue}
          index={0}
          dir={theme.direction}
          aria-labelledby="Add a Widget Panel"
        >
          <AddWidgets
            dispatch={dispatch}
            search={search}
            setSearch={setSearch}
            setEdited={setEdited}
            selectedWidgets={selectedWidgets}
            setSelectedWidgets={setSelectedWidgets}
            expandedIndicies={expandedIndicies}
            setExpandedIndicies={setExpandedIndicies}
            selectedIndicies={selectedIndicies}
            setSelectedIndicies={setSelectedIndicies}
          />
        </TabPanel>
        <TabPanel
          value={tabValue}
          index={1}
          dir={theme.direction}
          aria-labelledby="Reorder Widgets Panel"
        >
          <ReorderWidgets
            dispatch={dispatch}
            setEdited={setEdited}
            dashboardWidgets={dashboardWidgets}
            setDashboardWidgets={setDashboardWidgets}
            updateWidgets={updateWidgets}
            widgets={widgets}
          />
        </TabPanel>
      </Grid>
    </Grid>
  )
}

export default ManageWidgets
export { FooterButton }

import * as React from 'react'
import { Grid, Typography as Text, Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
import FlatList from './FlatList'
import App from '../App/App'
import AppAlt from '../AppAlt/AppAlt'
import ShowAppListStyles from './ShowAppListStyles'
import { selectIsGuest } from '../../appSlice'
import SetCarouselDimensions from '../AppCarouselCombined/AppCarouselDimensions'
import IconPicker from '../IconPicker/IconPicker'

function ShowAppList({
  isMobile = false,
  inputAppList,
  appAltList = false,
  appAltListTitle = { showTitle: false, title: '' },
  showListEmptyComponent = true,
  searchMode = false,
}) {
  const classes = ShowAppListStyles()
  const isGuest = useSelector(selectIsGuest)
  const { gridWidth } = SetCarouselDimensions()

  const EmptyComponent = () =>
    showListEmptyComponent && (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '40px',
        }}
      >
        <IconPicker iconName="fas fa-search-minus" color="red" size="2x" />
        <Text className={classes.noMatches}>&nbsp;No Matches Available</Text>
      </Box>
    )
  const returnList = () => {
    if (appAltList) {
      return (
        <>
          {appAltListTitle.showTitle && (
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              className={classes.popularText}
            >
              <Grid item>
                <Text className={classes.popularAppsHeader}>
                  {appAltListTitle.title}
                </Text>
              </Grid>
            </Grid>
          )}
          <FlatList
            data={inputAppList}
            renderItem={(app) => (
              <Grid item>
                <AppAlt
                  key={app.id}
                  app={app}
                  isGuest={isGuest}
                  isMobile={isMobile}
                />
              </Grid>
            )}
            ListEmptyComponent={showListEmptyComponent && <EmptyComponent />}
            keyExtractor={(app) => app.id}
          />
        </>
      )
    }

    return (
      <Grid
        container
        justifyContent="center"
        className={classes.showAppListContainer}
      >
        <FlatList
          data={inputAppList}
          renderItem={(app) => (
            <Grid item xs={gridWidth} style={{ padding: 0, margin: 0 }}>
              <App
                isGuest={isGuest}
                key={app.id}
                app={app}
                isMobile={isMobile}
                appList={inputAppList}
                searchMode={searchMode}
              />
            </Grid>
          )}
          ListEmptyComponent={showListEmptyComponent && <EmptyComponent />}
          keyExtractor={(app) => app.id}
        />
      </Grid>
    )
  }
  return <>{returnList()}</>
}

export default ShowAppList

import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Divider, IconButton, Typography, Grid } from '@material-ui/core'

import Paper from '@material-ui/core/Paper'
import DragHandleIcon from '@material-ui/icons/DragHandle'

import styles from './SortableItemStyles'
import Widget from '../../../../dashboard/widgets/Widget'

export function Item(props) {
  const { source, listeners, activeId, overlay } = props
  const classes = styles()

  const opacity = {
    opacity: activeId && activeId.id === source.id && !overlay ? 0 : 1,
  }

  return (
    <Paper
      className={classes.card}
      style={opacity}
      variant="outlined"
      aria-label={source.humanReadableSectionName}
    >
      <Grid
        container
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="subtitle1">
            {source && source.humanReadableSectionName && (
              <>
                {source.humanReadableSectionName
                  .split(' ')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
              </>
            )}
          </Typography>
        </Grid>
        <Grid
          item
          aria-label={`Reorder ${source.humanReadableSectionName} category`}
        >
          <IconButton {...listeners}>
            <DragHandleIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" className={classes.divider} />
          <Widget.Spacer />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Item {...props} listeners={listeners} />
    </div>
  )
}

import React from 'react'

import { Switch, useRouteMatch } from 'react-router-dom'

import Box from '@material-ui/core/Box'

import navigation from './settingsNavigation.constants'
import styles from './settingsStyles'
import { PrivateRoute } from '../../helpers'

// TODO Navigating to settings should show you as on the dashboard,
// myApps, News, Maps tab and highlight that as the active tab
// This should be done after SideNav rework.
function Settings() {
  const classes = styles()

  const { path } = useRouteMatch()

  return (
    <Box className={classes.main} id="main-content">
      <Switch>
        {navigation.map((item) => (
          <PrivateRoute
            key={item.path}
            exact
            path={`${path}${item.path}`}
            component={item.component}
            classes={classes}
          />
        ))}
      </Switch>
    </Box>
  )
}

export default Settings

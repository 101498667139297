import { Grid, Snackbar } from '@material-ui/core'
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers'
import Alert from '@material-ui/lab/Alert'
import useSound from 'use-sound'
import * as React from 'react'

import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'

import Container from '../../../../navigation/sideNav/customButtons/dnd-kit/Container'
import { Item } from '../../../../navigation/sideNav/customButtons/dnd-kit/SortableItem'

import { ReorderWidgetStyles } from './ReorderWidgetStyles'
import '../../../../navigation/sideNav/customButtons/helper.css'
import trashSfx from '../../../../navigation/sideNav/customButtons/trash.mp3'

function ReorderWidgets({
  dispatch,
  setEdited,
  dashboardWidgets,
  setDashboardWidgets,
  updateWidgets,
  widgets,
}) {
  const classes = ReorderWidgetStyles()
  const [playTrashSound] = useSound(trashSfx)

  const [activeId, setActiveId] = React.useState()

  const sensors = useSensors(
    useSensor(PointerSensor, {
      delay: 5,
    }),
  )

  const [snackBarOpen, setSnackbarOpen] = React.useState(false)

  const handleSnackbarClose = () => setSnackbarOpen(false)

  React.useEffect(() => {
    setDashboardWidgets(widgets)
  }, [])

  const handleDragStart = (event) => {
    const { active } = event
    const { id } = active
    const draggingWidget = dashboardWidgets.find(
      (widget) => parseInt(widget.id, 10) === parseInt(id, 10),
    )
    setActiveId(draggingWidget)
  }

  const handleDragEnd = (event) => {
    const { active, over } = event
    // eslint-disable-next-line no-shadow
    const { id: activeId } = active
    const { id: overId } = over
    const oldIndex = dashboardWidgets.findIndex(
      (widget) => parseInt(widget.id, 10) === parseInt(activeId, 10),
    )
    const newIndex = dashboardWidgets.findIndex(
      (widget) => parseInt(widget.id, 10) === parseInt(overId, 10),
    )
    // need a copy of the dashboardWidgets[]
    const copy = dashboardWidgets.map((dashboardWidget) => ({
      ...dashboardWidget,
    }))
    copy[oldIndex].manuallyMoved = Date.now()
    const newArray = arrayMove(copy, oldIndex, newIndex)
    setEdited(true)
    setActiveId(null)
    setDashboardWidgets(newArray)
  }

  const onDelete = (id) => {
    const oldIndex = dashboardWidgets.findIndex(
      (widget) => parseInt(widget.id, 10) === parseInt(id, 10),
    )
    const updatedItem = dashboardWidgets[oldIndex]
    if (updatedItem.lock) {
      setSnackbarOpen(true)
      return
    }

    const newDashboardWidgets = dashboardWidgets.filter(
      (widget) => widget.id !== id,
    )

    setDashboardWidgets(newDashboardWidgets)

    // update local store
    dispatch(updateWidgets(newDashboardWidgets))

    playTrashSound()
  }

  return (
    <>
      <Grid container direction="column" wrap="nowrap">
        <Grid item className={classes.content}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <div className={classes.dndDiv}>
              <Container
                id="active"
                items={dashboardWidgets.filter(
                  (widget) => widget.showOnDashboard,
                )}
                activeId={activeId}
                onDelete={onDelete}
              />
            </div>
            <DragOverlay modifiers={[restrictToParentElement]}>
              {activeId ? <Item widget={activeId} overlay /> : null}
            </DragOverlay>
            <Snackbar
              autoHideDuration={1000}
              open={snackBarOpen}
              onClose={handleSnackbarClose}
            >
              <Alert severity="error">Can&rsquo;t delete this widget!</Alert>
            </Snackbar>
          </DndContext>
        </Grid>
      </Grid>
    </>
  )
}

export default ReorderWidgets
export { ReorderWidgets }

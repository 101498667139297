import React from 'react'
import { useSelector } from 'react-redux'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Divider, IconButton, Typography } from '@material-ui/core'

import Paper from '@material-ui/core/Paper'
import DragHandleIcon from '@material-ui/icons/DragHandle'
// import LockIcon from '@material-ui/icons/Lock'
// import Delete from '@material-ui/icons/Delete'

import styles from './SortableItemStyles'
import Widget from '../../../../dashboard/widgets/Widget'

export function Item(props) {
  const { activeWidgetIds } = useSelector((s) => s.adminPage)
  const { widget, listeners, activeId, overlay } = props
  const classes = styles()
  const opacity = {
    opacity: activeId && activeId.id === widget.id && !overlay ? 0 : 1,
    background:
      props.widgetIdReference?.id &&
      activeWidgetIds.includes(props.widgetIdReference?.id)
        ? 'pink'
        : null,
  }
  return (
    <Paper className={classes.card} style={opacity} variant="outlined">
      <div className={classes.rowContainer}>
        <Typography variant="subtitle1">{widget && widget.title}</Typography>
        <div>
          {/* <IconButton onClick={() => onDelete(widget.id)}>
            {widget.lock ? (
              <LockIcon />
            ) : (
              <Delete className={classes.trashCanIcon} />
            )}
          </IconButton> */}
          <IconButton {...listeners}>
            <DragHandleIcon />
          </IconButton>
        </div>
      </div>
      <Divider variant="fullWidth" className={classes.divider} />
      <Widget.Spacer />
    </Paper>
  )
}

export default function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: isDragging ? '#cc0033' : null,
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Item {...props} listeners={listeners} widgetIdReference={props.widget} />
    </div>
  )
}

import * as React from 'react'
import { Box, Typography, Grid, Divider } from '@material-ui/core'
import CRamenityCard from './CRAmenityCard'
import crStyles from '../crStyles'

const CRamenities = ({ data }) => {
  const classes = crStyles()
  return (
    <Box>
      <Box className={classes.eventsHeaderContainer}>
        <Typography variant="h5">
          <b>{data.title}</b>
        </Typography>
      </Box>
      <Divider />
      <Box className={classes.amenitiesListContainer}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {data.lounges.map((amData) => (
            <Grid key={amData.TITLE} item>
              <CRamenityCard data={amData} classes={classes} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}
export default CRamenities

import { makeStyles } from '@material-ui/core/styles'
import { desktopSideNavWidth } from '../../navigation/navigationValues'

export const useNewsStyles = makeStyles((theme) => ({
  centerPage: {
    padding: '60px 0px 0px 55px',
    [theme.breakpoints.down('sm')]: {
      padding: '60px 0px 0px 0px',
    },
    background: theme.palette.components.newsBackground,
  },
  sideNavOpen: {
    paddingLeft: desktopSideNavWidth * 0.8,
    overflow: 'hidden',
    transition: 'width 0.4s',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  pageContainer: {
    padding: '0px 80px 20px 80px', // Hacky way. Need to find a margin auto alternative
    [theme.breakpoints.down('lg')]: {
      padding: '0 20px 0 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 0 0',
    },
    backgroundColor: theme.palette.components.newsSectionBackground,
  },
  searchBar: {
    margin: '8px',
    background: theme.palette.components.newsBackground,
  },
  dateText: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    textAlign: 'left',
    color: theme.palette.text.primary,
    fontSize: '19px',
    verticalAlign: 'bottom',
    lineHeight: '30px',
  },
  sectionHeader: {
    textTransform: 'capitalize',
    margin: '15px 4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    textAlign: 'left',
    color: theme.palette.text.primary,
    fontSize: '39px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  newsArticleItemContainer: {
    padding: '0',
  },
  noMatches: {
    color: theme.palette.primary.headerText,
    fontSize: 20,
    fontWeight: '600',
    marginLeft: 'auto',
  },
  contentContainer: {
    transition: 'margin-top 500ms linear',
  },
  errorSectionText: {
    marginTop: 15,
    fontStyle: 'bold',
  },

  errorColorbox: {
    width: 600,
    height: 200,
    padding: 5,
    backgroundColor: theme.palette.background.default,
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    borderRadius: 10,
    marginBottom: 50,
    textAlign: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: 500,
    },
    [theme.breakpoints.down('xs')]: {
      width: 300,
    },
  },

  errorContainer: {
    marginTop: 50,
    backgroundColor: theme.palette.components.newsSectionBackground,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '0 auto',
  },
  marginTop: {
    marginTop: 0,
  },
}))

export default useNewsStyles

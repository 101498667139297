/* eslint-disable no-param-reassign */
import moment from 'moment'
import uuid from 'react-uuid'
import { updateDoc, doc, getDoc } from 'firebase/firestore'
import { firestore } from '../../../utils/firebase'
import { MAX_ARTICLES_PER_SECTION, sectionMap } from './constants'

// Sort news by date
export function sortNewsByDate(news) {
  return news.sort((a, b) => (a.pubDateEpoch < b.pubDateEpoch ? 1 : -1))
}

export function handleEpochDate(epoch) {
  const todaysDate = moment()
  if (epoch) {
    const articleEpoch = moment(parseInt(epoch, 10) * 1000)
    const differenceInDays = todaysDate.diff(articleEpoch, 'days')
    if (differenceInDays === 0) {
      return `${todaysDate.diff(articleEpoch, 'hours')} hours ago`
    }
    return `${differenceInDays} days ago`
  }
  return ''
}

// Create custom object based of news sources
export function mapSources(endpointResponse) {
  const finalSourceMapping = []
  endpointResponse.forEach((source) => {
    const mappedSource = {
      id: uuid(),
      name: source.id,
      sourceID: source.sid,
      sourceURL: source.url,
      lock: source.lock,
      push: source.push,
      filter: source.filter,
      type: source.type,
    }
    finalSourceMapping.push(mappedSource)
  })

  return finalSourceMapping.sort((a, b) => (a.name > b.name ? 1 : -1))
}

// Core of News Section
// Reformats articles and organizes it into different sections
export function sortNewsArticlesIntoSections(newsArticles, sources) {
  const RUTGERS_TODAY_SOURCE_ID = 11
  const MAX_SOURCE_ID = 50
  const NUM_ARTICLES_TO_DISPLAY_PER_SECTION = MAX_ARTICLES_PER_SECTION
  const articlesCategory = {
    rutgersToday: [],
    top: [],
  }
  const rest = []

  const sourceMap = Object.fromEntries(sources.map((x) => [x.sourceID, x.name]))
  newsArticles.forEach((article) => {
    // Dynamically creating the sections
    if (article.tag !== null && !(article.tag in articlesCategory)) {
      articlesCategory[article.tag] = []
    }
    const formattedArticle = formatArticle(article, sourceMap)
    if (
      formattedArticle.sourceID === RUTGERS_TODAY_SOURCE_ID &&
      formattedArticle.imageUrl !== null
    ) {
      articlesCategory.rutgersToday.push(formattedArticle)
    }

    if (
      formattedArticle.sourceID > RUTGERS_TODAY_SOURCE_ID &&
      formattedArticle.sourceID <= MAX_SOURCE_ID &&
      // formattedArticle.imageUrl !== null && // TO DO: Find a way to display articles without images in sections
      formattedArticle.tag in articlesCategory
    ) {
      articlesCategory[formattedArticle.tag].push(formattedArticle)
    } else {
      rest.push(formattedArticle)
    }
  })
  console.log('newsArticles ', newsArticles)
  // Iterate through each category and get the first 5 items
  // Then add the rest to rest
  // Finally sort articles
  Object.keys(articlesCategory).forEach((category) => {
    const sortedArticlesByDate = sortNewsByDate(articlesCategory[category])
    articlesCategory[category] = sortedArticlesByDate.slice(
      0,
      NUM_ARTICLES_TO_DISPLAY_PER_SECTION,
    )

    const remainingArticles = sortedArticlesByDate.slice(
      NUM_ARTICLES_TO_DISPLAY_PER_SECTION,
      sortedArticlesByDate.length,
    )
    if (remainingArticles.length > 0) {
      rest.push(...remainingArticles)
    }
  })

  // Sort the rest of the articles after everything else is done and add to map
  articlesCategory.rest = sortNewsByDate(rest)
  // console.log('articlesCategory ', articlesCategory)
  return articlesCategory
}

function formatArticle(article, sourceMap) {
  return {
    ...article,
    id: article.id,
    title: article.id,
    sourceID: article.sid,
    sourceName: sourceMap[article.sid],
    author: article.author,
    click_thru: article.click_thru,
    description: article.description,
    filter: article.filter,
    imageUrl: article.imageUrl,
    published: handleEpochDate(
      article.pubDate?.seconds ? article.pubDate.seconds : Date.now(),
    ),
    pubDateEpoch: article.pubDate?.seconds
      ? article.pubDate.seconds
      : Date.now(),
    tag: article.tag,
    type: article.type,
    url: article.url,
    view_count: article.view_count,
    view_count_wk: article.view_count_wk,
  }
}
// Format tweet into usable object
export function formatTweet(tweet) {
  const author = tweet.includes?.users[0]?.username
  const image = tweet.entities?.urls.find((url) => Object.hasOwn(url, 'images'))
    ?.images[0]?.url
  const time = Math.floor(new Date(tweet.created_at) / 1000)
  return {
    author: `@${author}`,
    categories: [],
    click_thru: null,
    description: tweet.text,
    filter: null,
    id: `@${author}: ${tweet.text?.substring(0, 100)}...`,
    imageUrl: image === undefined ? null : image,
    pubDate: { seconds: time },
    sid: 50,
    sourceEntryId: 'Twitter',
    tag: 'social',
    type: null,
    url: `https://twitter.com/i/web/status/${tweet.id}`,
    view_count: null,
    view_count_wk: null,
  }
}

export function formatFacebookArticle(facebookArticle) {
  return {
    author: facebookArticle.author,
    categories: facebookArticle.categories,
    click_thru: facebookArticle.click_thru,
    description: facebookArticle.description,
    filter: facebookArticle.filter,
    sourceID: facebookArticle.sid,
    sourceName: facebookArticle.sourceEntryId,
    id: facebookArticle.id,
    imageUrl: facebookArticle.imageUrl,
    pubDate: facebookArticle.pubDate,
    pubDateEpoch: facebookArticle.pubDate.seconds,
    published: handleEpochDate(
      facebookArticle.pubDate?.seconds
        ? facebookArticle.pubDate.seconds
        : Date.now(),
    ),
    sid: facebookArticle.sid,
    sourceEntryId: facebookArticle.sourceEntryId,
    tag: 'social',
    type: facebookArticle.type,
    url: facebookArticle.url,
    view_count: facebookArticle.view_count,
    view_count_wk: facebookArticle.view_count_wk,
    ...facebookArticle,
  }
}

// Update user settings with any missing sections
export const checkIfUserHasAllSectionsOrderAndCreateThemIfTheyDont = (
  sections,
  newsData,
) => {
  let edited = false
  const defaultSections = new Set(Object.keys(sectionMap))
  const sectionsFromEndpoint = new Set(Object.keys(newsData))

  // If user has nothing, init the default for them
  if (sections.size === 0) {
    sections = defaultSections
    edited = true
  }
  const combinedSet = new Set([
    ...sections,
    ...defaultSections,
    ...sectionsFromEndpoint,
  ])

  // Checking if there are any news tags in sectionsFromEndpoint
  if (sections.size < combinedSet.size) {
    const difference = new Set([...combinedSet].filter((x) => !sections.has(x)))
    sections = [...sections, ...difference]
    edited = true
  }

  sections = Array.from(sections)
  if (edited) {
    // if edited, ensure rest is last and update firebase
    const restIndex = sections.indexOf('rest')
    if (restIndex !== -1) {
      sections.splice(restIndex, 1)
      sections.push('rest')
    }
    setSectionsOrder(sections)
  }

  return sections
}

export const extractSectionsFromNewsList = (newsList) => {
  const sortedTags = newsList
    .map(({ tag, order }) => ({
      tag,
      order,
    }))
    .sort((a, b) => a.order - b.order)
    .map((item) => item.tag)

  return sortedTags
}

// Fetch user's news preferences
// Regular async function to fetch user news preferences
export const fetchUserNewsPreferences = async () => {
  try {
    const firebaseID = localStorage.getItem('rutgersEduRCPID')
    const newsRef = doc(firestore, 'users', firebaseID, 'settings', 'news')
    const docSnapshot = await getDoc(newsRef)
    return docSnapshot.data()
  } catch (error) {
    console.error('Error fetching user news preferences:', error)
    throw error
  }
}

// Store this new list to firebase
export const setSectionsOrder = async (sectionsList) => {
  const firebaseID = localStorage.getItem('rutgersEduRCPID')
  const newsRef = doc(firestore, 'users', firebaseID, 'settings', 'news')

  try {
    await updateDoc(newsRef, { sections: sectionsList })
    console.log('Sections order updated successfully')
  } catch (error) {
    console.error('Error updating sections order:', error)
  }
}

// Store this new list to firebase
export const setUnsubscribedNewsSource = async (list) => {
  const firebaseID = localStorage.getItem('rutgersEduRCPID')
  const newsRef = doc(firestore, 'users', firebaseID, 'settings', 'news')

  try {
    await updateDoc(newsRef, { ignoreSources: list })
    console.log('Unsubscribed news sources updated successfully')
  } catch (error) {
    console.error('Error updating unsubscribed news sources:', error)
  }
}

export const fetchUserLocationCode = async () => {
  try {
    const firebaseID = localStorage.getItem('rutgersEduRCPID')
    const userDoc = doc(firestore, 'users', firebaseID, 'userInfo', 'Info')
    const docSnapshot = await getDoc(userDoc)
    return docSnapshot?.data()?.locationCode
  } catch (error) {
    console.error('Error fetching user location code:', error)
    throw error
  }
}

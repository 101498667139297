import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, Button } from '@material-ui/core'

import InlineIconButtons from '../../DashboardComponents/InlineIconButtons/InlineIconButtons'
import InlineSocialMediaIcons from '../../DashboardComponents/InlineSocialMediaIcons/InlineSocialMediaIcons'
import LinkList from '../../DashboardComponents/LinkList.js/LinkList'
import Widget from '../Widget'
import Loading from '../helpers/Loading'

import {
  fetchMasonGrossTheater,
  selectMasonGrossTheaterData,
  selectMasonGrossTheaterStatus,
} from './MasonGrossTheaterSlice'
import styles from './MasonGrossTheaterStyles'
import { logDashboardEvent } from '../../events'
import { getImageFromFirebaseWithHook } from '../../../apps/helpers'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

function MasonGross({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const widgetData = useSelector(selectMasonGrossTheaterData)
  const widgetStatus = useSelector(selectMasonGrossTheaterStatus)
  const classes = styles()

  React.useEffect(() => {
    dispatch(fetchMasonGrossTheater())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        className={classes.container}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderWidget(widgetStatus, widgetData, classes, widgetId, dispatch)}
      </Widget.Content>
    </Widget>
  )
}

function renderWidget(widgetStatus, widgetData, classes, widgetId, dispatch) {
  switch (widgetStatus) {
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorMessage />
    case 'fulfilled':
      return (
        <Loaded
          widgetData={widgetData}
          classes={classes}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      )
    default:
      return <ErrorMessage />
  }
}

function Loaded({ widgetData, classes, widgetId, dispatch }) {
  const [image, setImg] = React.useState(null)

  React.useEffect(() => {
    if (widgetData && widgetData.imageLocation) {
      getImageFromFirebaseWithHook(widgetData.imageLocation, setImg)
    }
  }, [])

  const handleButtonClick = (link, title) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, title, link)
    }
    window.open(link, '_blank')
  }

  const linkList = widgetData.linkList.map((item) => {
    const newLabel = item.iconLabel.replace('\\n', '\n')
    return { ...item, iconLabel: newLabel }
  })
  // firebase is returning \\n when only \n is submitted. This replaces the incorrect escape character.
  // this can be removed if the backend returns the data without \\n

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.body}
    >
      <Grid item container direction="column" className={classes.content}>
        <Grid
          item
          component={Button}
          className={classes.imageGrid}
          onClick={() =>
            handleButtonClick(widgetData.imageURL, widgetData.title)
          }
        >
          <img src={image} alt={widgetData.title} className={classes.image} />
        </Grid>
        <Grid item className={classes.iconsGridItem}>
          <InlineIconButtons
            buttons={widgetData.inlineIconButtons}
            widgetId={widgetId}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item>
          <LinkList
            links={linkList}
            // links={widgetData.linkList}
            widgetId={widgetId}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item container direction="column" alignItems="center">
          <Grid item>
            <Typography variant="subtitle1">Address</Typography>
          </Grid>
          <Grid item xs direction="column" alignItems="center">
            <Typography align="center" variant="caption">
              {widgetData.description.school}
            </Typography>
          </Grid>
          <Grid item xs direction="column" alignItems="center">
            <Typography align="center" variant="caption">
              {widgetData.description.address}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.footer}>
        <InlineSocialMediaIcons
          buttons={widgetData.inlineSocialMediaIcons}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </Grid>
    </Grid>
  )
}

export default MasonGross

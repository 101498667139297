import * as React from 'react'
import moment from 'moment'
import { Box } from '@material-ui/core'
import CReventCard from './CReventCard/CReventCard'
import CRevents from './CRevents'
import { eventCardStyles } from './CReventCard/eventCardStyles'

const CReventList = ({ events, handleClick, backupData }) => {
  const classes = eventCardStyles()
  const filteredEvents = [...events]
    .sort((a, b) => moment(a.startsOn) - moment(b.startsOn))
    // sort chronologically
    .filter((event) => {
      console.log(event)
      if (!moment(event.startsOn).isBefore(moment())) {
        // keep events in the future
        return true
      }
      // discard events in the past
      return false
    })
  return (
    <Box className={classes.eventsTabContent}>
      {filteredEvents.length === 0 ? (
        <CRevents handleClick={handleClick} data={backupData} />
      ) : (
        filteredEvents?.map((event) => (
          <Box key={event.title}>
            <CReventCard
              classes={classes}
              data={event}
              handleClick={handleClick}
            />
          </Box>
        ))
      )}
    </Box>
  )
}

export default CReventList

import * as React from 'react'
// import { useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
// import Button from '@material-ui/core/Button'
import styles from './NewsStyles'

function ErrorPage() {
  const classes = styles()
  // const dispatch = useDispatch()

  return (
    <Box className={classes.errorContainer}>
      <div className={classes.errorColorbox}>
        <div className={classes.errorSectionText}>
          <Typography variant="h1">Error</Typography>
          <Typography variant="h5">
            There was an issue fetching the News. Please try again later.
          </Typography>
        </div>
        {/* <Button
          component="label"
          variant="contained"
          className={classes.errorPageLogout}
          onClick={() => dispatch(handleLogout())}
          startIcon={<ExitToApp />}
        >
          Logout
        </Button> */}
      </div>
    </Box>
  )
}

export default ErrorPage

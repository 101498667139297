import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  smallIcon: {
    marginRight: '5px',
    width: '40px',
  },
  clickable: {
    textAlign: 'left',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.button.hoverLight,
    },
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
    },
  },
  iconLabel: {
    whiteSpace: 'pre-line',
  },
}))

export default styles

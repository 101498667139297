import * as React from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { useJsApiLoader } from '@react-google-maps/api'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchRoutes,
  fetchStops,
  fetchTrips,
  resetMap,
  setLatLang,
} from './sections/Map/redux/myMapsSlice'

import MapSearchBar from './components/Search/MapSearchBar'
import MapPanel from './sections/Map/MapPanel'
import MapPanelMobile from './mobile/MapPanelMobile/MapPanelMobile'
import BuildingPanel from './sections/Buildings/BuildingPanel'
import {
  resetParking,
  fetchBuildings,
  resetBuilding,
} from './sections/Buildings/redux/myBuildingsSlice'

import { getOverallState } from './sections/Map/helpers/helpers'
import { getLatLang } from './sections/Buildings/utils/helpers'

export default function MyMap() {
  const dispatch = useDispatch()
  const { selectedCampus } = useSelector((s) => s.maps)
  const { stopsLoading, routesLoading } = useSelector((state) => state.maps)
  const [mapState, setMapState] = React.useState('pending')

  const { isLoaded } = useJsApiLoader({
    id: 'rutgers-my-map',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  React.useEffect(() => {
    if (mapState !== 'fulfilled') {
      const loadedState = isLoaded ? 'fulfilled' : 'pending'
      // Sometimes requests get hung up here. Unclear why
      const state = getOverallState([loadedState, stopsLoading, routesLoading])
      setMapState(state)
    }
  }, [isLoaded, stopsLoading, routesLoading])

  // Makes an initial call to fetch static data to be used throughout app
  // Maybe in the future, we can have components that need it call API but this is the easiest way for now
  React.useEffect(() => {
    resetAll()
    dispatch(fetchStops()).then(() => {
      dispatch(fetchTrips()).then(() => {
        dispatch(fetchRoutes())
      })
    })
    dispatch(fetchBuildings())
    dispatch(setLatLang(getLatLang(selectedCampus)))
  }, [dispatch])

  // Resets the map when user navigates to another module.
  React.useEffect(
    () => () => {
      resetAll()
    },
    [],
  )

  const resetAll = () => {
    dispatch(resetMap())
    dispatch(resetParking())
    dispatch(resetBuilding())
  }

  return (
    <Box display="flex" flexDirection="column">
      {renderMyMapState(mapState)}
    </Box>
  )
}

function renderMyMapState(mapState) {
  switch (mapState) {
    case 'fulfilled':
      return <LoadedMap />
    case 'pending': // TODO: AddBetter loading indicator. Maybe a moving bus
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress style={{ marginTop: 100, color: '#cc0033' }} />
        </Box>
      )
    default:
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          Error
        </Box>
      )
  }
}

// TODO Web and mobile view is so entangled that it's causing extra renders when it shouldn't.
// These should get separated out
function LoadedMap() {
  return (
    <div id="main-content">
      <MapSearchBar />
      <MapPanelMobile />
      <MapPanel />
      <BuildingPanel /> {/* TODO Fix building panel */}
    </div>
  )
}

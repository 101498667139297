import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { FacebookEmbed, InstagramEmbed } from 'react-social-media-embed'
import {
  Box,
  AppBar,
  Toolbar,
  Grid,
  Button,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import { fetchNews } from '../../newsSlice'
import CheckIsMobile from '../../../../../utils/isMobileHook'
import useViewArticlePageStyles from './ViewArticlePageStyles'
import { addProfileLogEvent } from '../../../dashboard/widgets/MyProfile/myProfileSlice'
import { handleEpochDate } from '../../helpers'

function ViewArticlePage() {
  const classes = useViewArticlePageStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const isMobile = CheckIsMobile()
  const { newsStatus, allArticles } = useSelector((s) => s.news)
  const [article, setArticle] = React.useState({
    id: '',
    author: '',
    sourceEntryId: '',
    pubDate: { seconds: 0 },
    imageUrl: '',
    description: '',
  })

  React.useEffect(() => {
    createPage()
  }, [newsStatus])

  React.useEffect(() => {
    const query = location.search.concat(location.hash)
    if (allArticles) {
      const selectedNewsArticle = allArticles?.filter((c) => {
        const selectedArticle =
          c.id?.trim() === decodeURIComponent(query?.slice(8)).trim() ||
          c.id?.trim().replace(/\n/g, '') ===
            decodeURIComponent(query?.slice(8)).trim()
        return selectedArticle
      })[0]
      //   if (article !== selectedNewsArticle) {
      setArticle(selectedNewsArticle)
      //   }
    }
  }, [])

  const createPage = () => {
    if (newsStatus === 'idle' || newsStatus === 'pending') {
      const fetchData = async () => {
        try {
          await dispatch(fetchNews())
        } catch (error) {
          // Handle any potential error here
          console.error('Error fetching news:', error)
        }
      }
      fetchData()
    } else {
      const query = location.search.concat(location.hash)
      if (allArticles) {
        const selectedNewsArticle = allArticles?.filter((c) => {
          const selectedArticle =
            c.id?.trim() === decodeURIComponent(query?.slice(8)).trim() ||
            c.id?.trim().replace(/\n/g, '') ===
              decodeURIComponent(query?.slice(8)).trim()
          return selectedArticle
        })[0]
        // if (article !== selectedNewsArticle) {
        setArticle(selectedNewsArticle)
        // }
      }
    }
  }

  const learnMoreHandler = () => {
    if (process.env.REACT_APP_FIREBASE_ON === 'true') {
      dispatch(
        addProfileLogEvent({
          module: 'News',
          object: 'Articles',
          objectId: encodeURIComponent(article.id),
          action: 'Click',
          actionContext: 'ReadMore',
          actionContextValue: '',
          rcpid: localStorage.getItem('rutgersEduRCPID'),
          url: article.url,
          timestamp: Date.now(),
        }),
      )
    }
    window.open(article.url, '_blank')
  }

  const renderDescription = (articleObj) => {
    console.log('render description called ', articleObj)
    if (articleObj?.url?.toLowerCase().includes('facebook')) {
      // Handle the case where 'Facebook' is in the sourceName
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <FacebookEmbed url={articleObj.url} width={isMobile ? null : 550} />
        </div>
      )
    }
    if (articleObj?.url?.toLowerCase().includes('instagram')) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <InstagramEmbed url={articleObj.url} width={isMobile ? null : 550} />
        </div>
      )
    }
    return (
      <Typography variant="body1" className={classes.description}>
        {article?.description?.replace(/<([^>]+?)([^>]*?)>(.*?)/gi, '')}
      </Typography>
    )
  }

  return (
    <>
      {isMobile ? (
        <AppBar className={classes.appBar} position="relative" elevation={2}>
          <Toolbar className={classes.appBarContainer}>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item sm={4}>
                <IconButton onClick={() => history.goBack()}>
                  <ArrowBackIosIcon className={classes.mobileBackButtonIcon} />
                </IconButton>
              </Grid>
              <Grid item sm={4} className={classes.pageTitleContainer}>
                <Typography className={classes.pageTitle}>
                  {article?.id?.slice(0, 30)}
                  {' ...'}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      ) : null}
      <Grid
        container
        justifyContent="center"
        className={classes.articleContainer}
      >
        <Grid item>
          <Box className={classes.container}>
            <Box display="flex" alignItems="center">
              {isMobile ? null : (
                <IconButton onClick={() => history.goBack()}>
                  <ArrowBackIosIcon className={classes.mobileBackButtonIcon} />
                </IconButton>
              )}
              <Typography
                variant="h2"
                className={isMobile ? classes.titleBoxMobile : classes.titleBox}
              >
                {article?.id && article.id.length > 150
                  ? `${article.id.slice(0, 150)}...`
                  : article?.id}
              </Typography>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              className={classes.articleDetailsContainer}
            >
              <Grid item>
                {article?.author ? (
                  <Typography variant="caption" className={classes.author}>
                    By {article.author}
                  </Typography>
                ) : (
                  <Typography
                    variant="caption"
                    className={classes.articleSourceText}
                  >
                    {article?.sourceEntryId}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography variant="caption" className={classes.publishedDate}>
                  {handleEpochDate(article?.pubDate?.seconds)}
                </Typography>
              </Grid>
            </Grid>
            {article?.imageUrl && article?.tag !== 'social' ? (
              <img className={classes.image} src={article?.imageUrl} alt="" />
            ) : (
              <Divider className={classes.divider} />
            )}
            <Grid item> {renderDescription(article)} </Grid>
            <Grid item>
              <Button
                className={classes.learnMoreButton}
                onClick={learnMoreHandler}
              >
                Read More
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default ViewArticlePage

import { makeStyles } from '@material-ui/core/styles'

export const ManageNewsStyles = makeStyles((theme) => ({
  appBarIcon: {
    color: theme.palette.components.topBarIcon,
    fontSize: '24px',
  },
  tab: {
    minWidth: 30,
    paddingLeft: 0,
    paddingRight: 0,
  },
  helperText: {
    marginRight: theme.spacing(1),
  },
}))
